import { GenericService } from './generic.service';
import apiClient from './api';
import { AxiosResponse } from 'axios';

export class HealthCheckService extends GenericService<any> {
  endpoint = 'can-respond';

  async getHealthStatus(): Promise<AxiosResponse<string>> {
    return await apiClient.get(this.endpoint);
  }
}
