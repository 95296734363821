import { AxiosRequestConfig, AxiosResponse } from 'axios';
import apiClient from './api';

export class GenericService<T> {
  endpoint!: string;

  async index(params: AxiosRequestConfig = {}): Promise<AxiosResponse<Array<T>>> {
    return await apiClient.get(this.endpoint, params);
  }

  async fetch(id: string | number, params: AxiosRequestConfig = {}): Promise<T> {
    return (await apiClient.get(`${this.endpoint}/${id}`, params)).data;
  }

  async create(model: Partial<T>, params: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
    return await apiClient.post(this.endpoint, model, params);
  }

  async update(id: string | number, changes: Partial<T>, params: AxiosRequestConfig = {}): Promise<T> {
    return (await apiClient.patch(`${this.endpoint}/${id}`, changes, params)).data;
  }

  async delete(id: string | number, params: AxiosRequestConfig = {}): Promise<T> {
    return (await apiClient.delete(`${this.endpoint}/${id}`, params)).data;
  }
}
