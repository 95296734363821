import { AxiosError } from 'axios';
import { TokenService } from '@/services';
import apiClient from '../api';
import router from '@/router';
import { useHealthCheckService } from '@/composables/useHealthCheckService';
import { store } from '@/store';
import { REMOVE_CURRENT_USER } from '@/store/actions.type';
import { captureException } from '@sentry/vue';

export async function handleErrorResponse(error: AxiosError) {
  const tokenService = new TokenService();
  const { openModal } = useHealthCheckService();

  if (error.response) {
    const { status } = error.response;

    // Unauthorised - refresh token
    if (status === 401) {
      const isRefreshing = tokenService.getIsRefreshing() || 'false';
      const refreshToken = tokenService.getRefreshToken() || '';

      if (error.config.url?.includes('refresh-token')) {
        return Promise.reject(error);
      }

      if (isRefreshing !== 'false') {
        try {
          await tokenService.waitForTokenToRefresh();
          error.config.headers.Authorization = `Bearer ${tokenService.getAccessToken()}`;

          return apiClient(error.config);
        } catch (e) {
          return Promise.reject(error);
        }
      }

      if (!isRefreshing || isRefreshing === 'false') {
        tokenService.setIsRefreshing({ isRefreshing: 'true' });
        try {
          const res = await tokenService.refreshToken({
            refresh_token: refreshToken
          });

          tokenService.setAccessToken({ accessToken: res.access_token });
          tokenService.setRefreshToken({ refreshToken: res.refresh_token });
          error.config.headers.Authorization = `Bearer ${res.access_token}`;

          return apiClient(error.config);
        } catch (e) {
          captureException(e);
          tokenService.removeToken();
          store.dispatch(REMOVE_CURRENT_USER);
          router.push({ name: 'login' }).catch((error) => {
            if (!error.message.includes('Avoided redundant navigation to current location: "/".')) {
              throw error;
            }
          });
        } finally {
          tokenService.setIsRefreshing({ isRefreshing: 'false' });
        }
      }
    }

    if (status === 503) {
      openModal();
    }
  } else {
    openModal();
  }

  return Promise.reject(error);
}
