import apiClient from '../api';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { TokenService } from '@/services';
import { handleErrorResponse } from './helper';

const interceptorConfig = apiClient.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const tokenService = new TokenService();
    config.headers = {
      Authorization: `Bearer ${tokenService.getAccessToken()}`,
      ...config.headers
    };
    return config;
  },
  (error: any) => Promise.reject(error)
);

const interceptorErrors = apiClient.interceptors.response.use(
  (response: AxiosResponse) => response.data,
  (error: any) => handleErrorResponse(error)
);

export default { interceptorConfig, interceptorErrors };
