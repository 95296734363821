import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

const basicRoutes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    name: 'login',
    path: '/login',
    component: () => import('@/views/LoginPage.vue'),
    meta: { authRequired: false, loginPage: true }
  },
  {
    name: 'check-your-email',
    path: '/check-your-email',
    component: () => import('@/views/CheckYourEmail.vue'),
    meta: { authRequired: false }
  },
  {
    name: 'invalid-link',
    path: '/invalid-link',
    component: () => import('@/views/InvalidLink.vue'),
    meta: { authRequired: false }
  },
  {
    name: 'discharged',
    path: '/discharged',
    component: () => import('@/views/DischargedPage.vue'),
    props: {
      user: { name: 'User Test', email: 'test@email.com' },
      dischargedReason: 'Patient admitted to Hospital.'
    }
  },
  {
    path: '/tabs',
    component: () => import('@/views/TabsLayout.vue'),
    props: true,
    children: [
      {
        path: '',
        redirect: '/tabs/measurement'
      },
      {
        name: 'Measurement',
        path: 'measurement',
        component: () => import('@/views/MeasurementPage.vue'),
        meta: { requiredAuth: true }
      },
      {
        name: 'Measurement Confirmation',
        path: 'measurement-success',
        component: () => import('@/views/MeasurementSuccess.vue'),
        meta: { requiredAuth: true }
      },
      {
        name: 'Account',
        path: 'account',
        component: () => import('@/views/AccountPage.vue'),
        meta: { requiredAuth: true }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: basicRoutes as RouteRecordRaw[]
});

export default router;
