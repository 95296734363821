import { RouteLocationNormalizedLoaded, RouteRecord, NavigationGuardNext } from 'vue-router';
import { TokenService } from '@/services';
import { store } from '@/store';
import { FETCH_CURRENT_USER } from '@/store/actions.type';

const requiresAuthentication = (routeLocation: RouteLocationNormalizedLoaded): boolean =>
  routeLocation.matched.some((route: RouteRecord) => route.meta.requiredAuth);

const tokenService = new TokenService();

export const beforeEach = async (
  to: RouteLocationNormalizedLoaded,
  _from: RouteLocationNormalizedLoaded,
  next: NavigationGuardNext
) => {
  const accessToken = tokenService.getAccessToken();

  if (!accessToken && requiresAuthentication(to)) {
    return next({ name: 'login' });
  }

  if (accessToken && !store.state.currentUser) {
    await store.dispatch(FETCH_CURRENT_USER);
  }

  if (accessToken && to.meta?.loginPage) {
    return next('/tabs');
  }

  return next();
};
