import { GenericService } from './generic.service';
import apiClient from './api';
import { CodeLabelItem } from '@/types';
import { AxiosResponse } from 'axios';

export class HealthRecordTerminologyService extends GenericService<any> {
  endpoint = '/health-record';

  async fetchTypeOfAir(): Promise<AxiosResponse<CodeLabelItem[]>> {
    return await apiClient.get(`${this.endpoint}/terminologies/method-of-oxygen-delivery`);
  }
}
