import { GenericService } from './generic.service';
import apiClient from './api';
import { AxiosResponse } from 'axios';
import { Option } from '@/types';
export class HealthRecordScaleService extends GenericService<any> {
  endpoint = '/health-record/scales';

  async getScalesBorg(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/borg-breathlessness-scale/amount-of-breathlessness`);
  }
}
