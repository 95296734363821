import { ref } from 'vue';
import HealthCheckModal from '@/components/Modal/HealthCheckModal.vue';
import { modalController } from '@ionic/core';
import { store } from '@/store';
import { HealthCheckService } from '@/services';
import { HIDE_MAINTENANCE_MODAL, SHOW_MAINTENANCE_MODAL } from '@/store/actions.type';

const FIFTEEN_SECONDS = 15 * 1000;

export const EXPONENTIAL_MULTIPLIER = 1.5;

export const useHealthCheckService = () => {
  const healthCheckService = new HealthCheckService();

  const healthCheckModal = ref<HTMLIonModalElement | null>(null);

  const healthChecksPerformed = ref(1);

  const checkHealthStatus = async () => {
    const response = await healthCheckService.getHealthStatus().catch(() => null);
    healthChecksPerformed.value++;

    if (response) {
      return closeModal();
    }

    queueHealthCheck();
  };

  const queueHealthCheck = () =>
    setTimeout(
      checkHealthStatus,
      Math.min(Math.pow(EXPONENTIAL_MULTIPLIER, healthChecksPerformed.value) * 1000, FIFTEEN_SECONDS)
    );

  const openModal = async () => {
    if (store.state.isMaintenanceModalVisible) {
      return;
    }

    store.dispatch(SHOW_MAINTENANCE_MODAL);

    healthCheckModal.value = await modalController.create({
      component: HealthCheckModal,
      cssClass: 'health-check-modal',
      swipeToClose: false,
      keyboardClose: false,
      backdropDismiss: false
    });

    healthCheckModal.value?.present();

    queueHealthCheck();
  };

  const closeModal = () => {
    healthCheckModal.value?.dismiss();
    healthCheckModal.value = null;

    store.dispatch(HIDE_MAINTENANCE_MODAL);
  };

  return {
    healthCheckModal,
    openModal,
    closeModal
  };
};
