import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([[_ctx.textColorClass, _ctx.bgColorClass, _ctx.borderColorClass, _ctx.sizeClasses, _ctx.block && 'w-full', _ctx.rounded && 'rounded'], "mk-badge inline-flex items-center justify-center font-semibold border text-center"])
  }, [
    (!_ctx.label)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(_ctx.flex && 'flex')
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(_ctx.flex && 'flex')
        }, _toDisplayString(_ctx.label), 3))
  ], 2))
}