import { GenericService } from './generic.service';
import apiClient from './api';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class LoginService extends GenericService<any> {
  endpoint = '/copd/patient-app/otp';

  async validateEmail(model: { email: string }, params: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await apiClient.post(this.endpoint, model, params);
  }
}
