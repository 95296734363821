import { HomeMonitoringPayload } from '@/types';
import apiClient from './api';
import { GenericService } from './generic.service';
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export class MeasurementService extends GenericService<any> {
  endpoint = '/copd/patient-app/measurements';

  async sendMeasurement(model: HomeMonitoringPayload, params: AxiosRequestConfig = {}): Promise<AxiosResponse> {
    return await apiClient.post(this.endpoint, model, params);
  }
}
