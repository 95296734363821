import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { RouteLocationNormalizedLoaded, NavigationGuardNext } from 'vue-router';
import { beforeEach } from '@/router/middleware';
import interceptors from '@/services/client/interceptors';
import * as Sentry from '@sentry/vue';
import { IonicVue } from '@ionic/vue';
import { TokenService } from './services';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/fonts.css';
import './theme/tailwind.css';
import './theme/variables.css';
import './theme/base.css';

import { store } from '@/store';
import AWS from 'aws-sdk/global';

const app = createApp({
  ...App,
  mounted: () => {
    interceptors;
  }
})
  .use(IonicVue)
  .use(router)
  .use(store);

Sentry.init({
  app,
  dsn: process.env.VUE_APP_SENTRY_DNS,
  // By default all prop values are sent to Sentry which could contain the
  // values of an input. We will turn that feature off to not expose PII.
  attachProps: false
});

new TokenService().setSentryUserFromStoredCredentials();

// Add middleware
router.beforeResolve(
  (routeTo: RouteLocationNormalizedLoaded, routeFrom: RouteLocationNormalizedLoaded, next: NavigationGuardNext) =>
    beforeEach(routeTo, routeFrom, next)
);

router.isReady().then(() => {
  app.mount('#app');
});

AWS.config = new AWS.Config({
  region: process.env.VUE_APP_AWS_KINESIS_REGION,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: process.env.VUE_APP_AWS_COGNITO_IDENTITY_ID ?? ''
  })
});
