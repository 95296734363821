import Axios, { AxiosInstance } from 'axios';

const baseClient: AxiosInstance = Axios.create({
  baseURL: process.env.VUE_APP_API_URI,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export default baseClient;
