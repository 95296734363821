import { GenericService } from './generic.service';
import apiClient from './api';
import { AxiosRequestConfig } from 'axios';
import { Auth0Token } from '@/types';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import * as Sentry from '@sentry/vue';
import { captureException } from '@sentry/vue';

dayjs.extend(utc);

const UNKNOWN_USER_ID = 'unknown-user';

export class TokenService extends GenericService<Auth0Token> {
  endpoint = '/copd/patient-app/tokens';

  async fetchToken(model: { email: string; otp: string }, params: AxiosRequestConfig = {}): Promise<Auth0Token> {
    return await apiClient.post(this.endpoint, model, params);
  }

  async refreshToken(model: { refresh_token: string }, params: AxiosRequestConfig = {}): Promise<Auth0Token> {
    const res: Auth0Token = await apiClient.post('v1/refresh-token', model, params);

    if (!res.access_token) {
      throw new Error(res.error_description || `Unexpected response from Auth0: ${JSON.stringify(res)}`);
    }

    return res;
  }

  setAuthenticatedUser(data: Auth0Token) {
    this.setAccessToken({ accessToken: data.access_token });
    this.setIsRefreshing({ isRefreshing: 'false' });
    this.setRefreshToken({ refreshToken: data.refresh_token });
    this.setLocalExpireDate({ expiresIn: data.expires_in });

    this.setSentryUserFromAccessToken(data.access_token);
  }

  setSentryUserFromAccessToken(accessToken: string) {
    try {
      Sentry.setUser({
        id: jwtDecode<JwtPayload>(accessToken).sub
      });
    } catch (e) {
      this.removeToken();
      Sentry.setUser({ id: UNKNOWN_USER_ID });
      captureException(e);
    }
  }

  setSentryUserFromStoredCredentials() {
    const token = this.getAccessToken();

    if (token) {
      this.setSentryUserFromAccessToken(token);
    } else {
      Sentry.setUser({ id: UNKNOWN_USER_ID });
    }
  }

  getAccessToken() {
    return localStorage.getItem('access_token');
  }

  getRefreshToken() {
    return localStorage.getItem('refresh_token');
  }

  getIsRefreshing() {
    return localStorage.getItem('is_refreshing');
  }

  setIsRefreshing({ isRefreshing }: { [key: string]: string }) {
    localStorage.setItem('is_refreshing', isRefreshing);
  }

  setAccessToken({ accessToken }: { [key: string]: string }) {
    localStorage.setItem('access_token', accessToken);
  }

  setRefreshToken({ refreshToken }: { [key: string]: string }) {
    localStorage.setItem('refresh_token', refreshToken);
  }

  setLocalExpireDate({ expiresIn }: { [key: string]: number }) {
    const expireDate = dayjs.utc().add(expiresIn, 'second').format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('expireDate', expireDate);
  }

  removeToken() {
    localStorage.clear();
    Sentry.configureScope((scope) => scope.setUser(null));
  }

  waitForTokenToRefresh(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      do {
        await new Promise((resolve) => setTimeout(resolve, 200));
      } while (this.getIsRefreshing() === 'true');

      this.getAccessToken() ? resolve() : reject();
    });
  }
}
