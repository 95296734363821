
import { computed, defineComponent, PropType, ref } from 'vue';

type ColorName = 'primary' | 'secondary' | 'success' | 'alert' | 'danger' | 'gray';

type SizeName = '2xsmall' | 'xsmall' | 'small' | 'medium' | 'large';

export interface BadgeColorDefinition {
  text: string;
  bg: string;
  border: string;
  strokedBorder: string;
  filledText: string;
  filledBg: string;
  filledBorder: string;
  filledStrokedBorder: string;
}

export type BadgeColorDefinitionList = {
  // eslint-disable-next-line
  [key in ColorName]: BadgeColorDefinition;
};

export type BadgeSizeClassList = {
  // eslint-disable-next-line
  [key in SizeName]: string;
};

export interface BaseBadgeProps {
  color: ColorName;
  size: SizeName;
  stroked?: boolean;
  filled?: boolean;
  block?: boolean;
  flex?: boolean;
  label?: string | null;
  rounded?: boolean;
}

export default defineComponent({
  name: 'BaseBadge',
  props: {
    /**
     * The color of the badge. Accepts `'primary' | 'secondary' | 'success' | 'alert' | 'danger' | 'gray'`
     */
    color: {
      type: String as PropType<ColorName>,
      default: 'primary',
      validator: (value: string) => ['primary', 'secondary', 'success', 'alert', 'danger', 'gray'].includes(value)
    },
    /**
     * The size of the badge. Accepts `'2xsmall' | 'xsmall' | 'small' | 'medium' | 'large'`
     */
    size: {
      type: String as PropType<SizeName>,
      default: 'medium',
      validator: (value: string) => ['2xsmall', 'xsmall', 'small', 'medium', 'large'].includes(value)
    },
    /**
     * Whether the badge is stroked or not.
     */
    stroked: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the badge is filled with color or not.
     */
    filled: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the badge is a block or not.
     */
    block: {
      type: Boolean,
      default: false
    },
    /**
     * Whether the badge is a flex class.
     */
    flex: {
      type: Boolean,
      default: false
    },
    /**
     * The label text of the badge.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * Whether the borders are rounded or not.
     */
    rounded: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const colors = ref<BadgeColorDefinitionList>({
      primary: {
        text: 'text-primary-800',
        bg: 'bg-primary-100',
        border: 'border-primary-100',
        strokedBorder: 'border-primary-400',
        filledBg: 'bg-primary-600',
        filledText: 'text-white',
        filledBorder: 'border-primary-600',
        filledStrokedBorder: 'border-primary-600'
      },

      secondary: {
        text: 'text-secondary-800',
        bg: 'bg-secondary-100',
        border: 'border-secondary-100',
        strokedBorder: 'border-secondary-300',
        filledText: 'text-white',
        filledBg: 'bg-secondary-500',
        filledBorder: 'border-secondary-500',
        filledStrokedBorder: 'border-secondary-500'
      },

      success: {
        text: 'text-success-800',
        bg: 'bg-success-100',
        border: 'border-success-100',
        strokedBorder: 'border-success-200',
        filledText: 'text-white',
        filledBg: 'bg-success-500',
        filledBorder: 'border-success-500',
        filledStrokedBorder: 'border-success-500'
      },

      alert: {
        text: 'text-alert-800',
        bg: 'bg-alert-100',
        border: 'border-alert-100',
        strokedBorder: 'border-alert-700',
        filledText: 'text-gray-900',
        filledBg: 'bg-alert-300',
        filledBorder: 'border-alert-300',
        filledStrokedBorder: 'border-alert-500'
      },

      danger: {
        text: 'text-danger-800',
        bg: 'bg-danger-100',
        border: 'border-danger-100',
        strokedBorder: 'border-danger-300',
        filledText: 'text-white',
        filledBg: 'bg-danger-500',
        filledBorder: 'border-danger-500',
        filledStrokedBorder: 'border-danger-500'
      },

      gray: {
        text: 'text-gray-800',
        bg: 'bg-gray-100',
        border: 'border-gray-100',
        strokedBorder: 'border-gray-600',
        filledText: 'text-white',
        filledBg: 'bg-gray-900',
        filledBorder: 'border-gray-900',
        filledStrokedBorder: 'border-gray-900'
      }
    });

    const sizes = ref<BadgeSizeClassList>({
      '2xsmall': 'px-1 py-0.5 text-2xs leading-tight',
      xsmall: 'px-1 py-1 text-xs leading-tight',
      small: 'px-2 py-1.5 text-xs leading-tight',
      medium: 'px-4 py-2 text-xs leading-none',
      large: 'px-4 py-3 text-sm leading-normal'
    });

    const textColorClass = computed(() =>
      props.filled ? colors.value[props.color].filledText : colors.value[props.color].text
    );

    const bgColorClass = computed(() => colors.value[props.color][props.filled ? 'filledBg' : 'bg']);

    const borderColorClass = computed(() => {
      if (!props.stroked) {
        return colors.value[props.color][props.filled ? 'filledBorder' : 'border'];
      }

      return colors.value[props.color][props.filled ? 'filledStrokedBorder' : 'strokedBorder'];
    });

    const sizeClasses = computed(() => sizes.value[props.size]);

    return {
      props,
      textColorClass,
      sizeClasses,
      bgColorClass,
      borderColorClass
    };
  }
});
