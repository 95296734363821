import { GenericService } from './generic.service';
import apiClient from './api';
import { AxiosResponse } from 'axios';
import { Option } from '@/types';

export class HealthRecordOrdinalService extends GenericService<any> {
  endpoint = '/health-record/ordinals/uhb-modified-anthonisen-criteria';

  async getBreathlessness(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/breathlessness`);
  }

  async getSputumProd(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/sputum-production`);
  }

  async getSputumColor(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/sputum-colour`);
  }

  async getWheeze(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/wheeze`);
  }

  async getCough(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/cough`);
  }

  async getSoreThroat(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/sore-throat`);
  }

  async getRunnyNose(): Promise<AxiosResponse<Option[]>> {
    return await apiClient.get(`${this.endpoint}/congested-runny-nose`);
  }
}
